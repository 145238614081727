<script>
import PageHeader from "@/components/page-header";
import { ApiClient } from "@/common/http-common"
import { BredcrumpManagerDecode } from "@/common/bredcrumb-common"
import { networkRequesErrorResponseLogic } from "@/common/http-common-response-error"
import { networkRequesListResponseLogic } from "@/common/http-common-response-list"
export default {
  components: {
    PageHeader
  },
  data() {
    return {
      title: this.$t('platforms.platforms'),
      items: [],
      pagination: {
        path: "",
        total: 0,
        totalPages: 0,
        page: 1,
        pageSize: 10,
        nextPages: [],
        backPages: []
      }
    };
  },
  methods: {
    getData: function (page, size) {
      const self = this;
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
      ApiClient().get("/v1/admin/domains/list?page=" + page + "&size=" + size).then(function (response) {
        networkRequesListResponseLogic(self, response, page, size, "platforms.platforms")
      }).catch(
        function (error) {
          networkRequesErrorResponseLogic(self, error)
        }
      )
    },
    configureDataNextModule: function (id) {
      if (this.$route.query.data) {
        var rs = JSON.parse(atob(this.$route.query.data))
        if (id != null) {
          rs.platformId = id
        }

        return btoa(JSON.stringify(rs))
      }
    },
  },
  mounted() {
    if (this.$route.query.data) {
      var data = BredcrumpManagerDecode(this)
      this.pagination.page = parseInt(data.page)
      this.pagination.pageSize = parseInt(data.size)
      this.getData(this.pagination.page, this.pagination.pageSize)
    } else {
      this.getData(this.pagination.page, this.pagination.pageSize)
    }
  }
};
</script>
<template>
  <div class="row">
    <div class="col-xl-12" style="padding: 0!important;">
      <PageHeader :title="title" />
    </div>
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <h4 class="card-title">{{ this.$t('platforms.platforms') }}</h4>
                <p class="card-title-desc">
                  <router-link :to="{ path: '/platform/create', query: { data: configureDataNextModule(null) } }">{{
        $t("platforms.create") }}</router-link>
                </p>
              </div>
              <div class="col-12" style="padding: 0px!important">
                <hr style="margin-top: 0px !important;">
              </div>
              <div class="col-12">
                {{ this.$t("platforms.list.platform") }}
              </div>
              <div class="col-12" style="padding: 0px!important">
                <hr>
              </div>
              <div class="col-12" v-for="(item, index) in items" :key="index">
                <div class="container" style="padding: 0px!important;">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-6">
                      {{ item.id }} - {{ item.title }} - {{ item.prefix }}
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6">
                      <ul class="list-group">

                        <li class="list-group-item" style="padding:0px 0px !important;border:0px!important"><router-link
                            :to="{ path: '/platform/detail', query: { data: configureDataNextModule(item.id) } }">{{
        $t("platforms.list.configuration")
      }}</router-link></li>
                      </ul>
                    </div>




                    <div class="col-sm-12 col-md-12">
                      <hr>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="pagination.totalPages != 1 && items.length != 0">
              <div class="col-sm-6">
                <div>
                  <p class="mb-sm-0 mt-2">
                    {{ this.$t("pagination.page") }}
                    <span class="font-weight-bold">{{ pagination.page }}</span> {{ this.$t("pagination.from") }}
                    <span class="font-weight-bold">{{ pagination.totalPages }}</span>
                  </p>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-right">
                  <ul class="pagination pagination-rounded mb-sm-0">
                    <li class="page-item" v-bind:class="(pagination.page == 1) ? 'disabled' : ''">
                      <a href="#" class="page-link"
                        @click="getData(parseInt(pagination.page) - 1, pagination.pageSize)">
                        <i class="mdi mdi-chevron-left"></i>
                      </a>
                    </li>
                    <li class="page-item" v-for="item in pagination.backPages" :key="item">
                      <a href="#" class="page-link" @click="getData(item, pagination.pageSize)">{{ item }}</a>
                    </li>
                    <li class="page-item active">
                      <a href="#" class="page-link">{{ pagination.page }}</a>
                    </li>
                    <li class="page-item" v-for="item in pagination.nextPages" :key="item">
                      <a href="#" class="page-link" @click="getData(item, pagination.pageSize)">{{ item }}</a>
                    </li>
                    <li class="page-item" v-bind:class="(pagination.page == pagination.totalPages) ? 'disabled' : ''">
                      <a href="#" class="page-link" @click="getData(parseIn(pagination.page) + 1, pagination.pageSize)">
                        <i class="mdi mdi-chevron-right"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>