import { getPreviousTwoPages, getNextTwoPages } from "@/common/pagination-common"
import { BredcrumpManagerEncode } from "@/common/bredcrumb-common"

export function networkRequesListResponseLogic(self, response, page, size, title) {
    self.items = response.data.items
    self.pagination.total = response.data.total
    self.pagination.totalPages = response.data.totalPages
    self.pagination.page = page
    self.pagination.pageSize = size

    self.pagination.nextPages = getNextTwoPages(self)
    self.pagination.backPages = getPreviousTwoPages(self)

    if (0 == parseInt(response.data.totalPages)) {
        if (title != ""){
            BredcrumpManagerEncode(self, { path: self.$route.path, title: title, page: self.pagination.page, size: self.pagination.pageSize })
        }
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
    } else {
        if (page > parseInt(response.data.totalPages)) {
            self.pagination.page = parseInt(self.pagination.totalPages)
            self.pagination.pageSize = parseInt(self.$route.query.size)
            self.getData(self.pagination.page, self.pagination.pageSize)
        } else {
            if (title != ""){
                BredcrumpManagerEncode(self, { path: self.$route.path, title: title, page: self.pagination.page, size: self.pagination.pageSize })
            }
            document.getElementById("preloader").style.display = "none";
            document.getElementById("status").style.display = "none";
        }
    }
}