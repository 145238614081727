import { showNotificationFromServer, showNotificationWithText } from "@/common/notification-common"

export function networkRequesErrorResponseLogic(self, error) {
  if (error) {
    if (error.response) {
      if (error.response.data) {
        if (error.response.data.code) {
          showNotificationFromServer(self, error.response.data.code)
        } else {
          showNotificationWithText(self, "error.server.internalServer")
        }
      } else {
        showNotificationWithText(self, "error.server.internalServer")
      }
    } else {
      showNotificationWithText(self, "error.server.internalServer")
    }
  } else {
    showNotificationWithText(self, "error.server.internalServer")
  }
}