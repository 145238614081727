export function showNotificationFromServer(self,code) {
    if (code == 'NO_ACCESS'){
        self.$router.push({path: "/" });
    }else if (code == 'INVALID_USER_SESSION'){
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
        const id = `my-toast-${code}`
        self.$bvToast.toast(self.$t(code), {
            id: id,
            title: self.$t("error.error"),
            variant: "error",
            toaster:"b-toaster-bottom-right",
            solid: true,
            href:"/logout"
        });
    }else{
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
        const id = `my-toast-${code}`
        self.$bvToast.toast(self.$t(code), {
            id:id,
            title: self.$t("error.error"),
            variant: "error",
            toaster:"b-toaster-bottom-right",
            solid: true
        });
    }
}
export function showNotificationWithText(self,message) {
    document.getElementById("preloader").style.display = "none";
    document.getElementById("status").style.display = "none";
    self.$bvToast.toast(self.$t(message), {
        title: self.$t("error.error"),
        variant: "error",
        toaster:"b-toaster-bottom-right",
        solid: true
    });
}

