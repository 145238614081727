export function getPreviousTwoPages(self) {
    const currentPage = self.pagination.page;
    let previousTwoPages = [];
    for (let i = currentPage - 1; i >= 1 && i >= currentPage - 2; i--) {
      previousTwoPages.unshift(i);
    }
    return previousTwoPages;
}
export function getNextTwoPages(self) {
    const currentPage = self.pagination.page;
    const totalPages = self.pagination.totalPages;
    let nextTwoPages = [];
    for (let i = currentPage + 1; i <= totalPages && i <= currentPage + 2; i++) {
      nextTwoPages.push(i);
    }
    return nextTwoPages;
}