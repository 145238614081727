export function BredcrumpManagerEncode(self,data) {
    if (self.$route.query.data){
        var rs = JSON.parse(atob(self.$route.query.data))
        if (rs.items){
            var updateIndex = null
            for (let i = 0; i < rs.items.length; i++) {
                if (rs.items[i].path === self.$route.path){
                    updateIndex = i
                }
            }
            if (updateIndex != null){
                rs.items[updateIndex] = data
                rs.items[updateIndex].name = self.$route.name
                var eca = btoa(JSON.stringify(rs))
                rs.items[updateIndex].data = eca
                eca = btoa(JSON.stringify(rs))
                self.$router.replace({ path: self.$route.fullPath, query: { data: eca} }).catch(()=>{});
            }else{
                data.name = self.$route.name
                rs.items.push(data)
                var ec = btoa(JSON.stringify(rs))
                rs.items[rs.items.length - 1].data = ec
                ec = btoa(JSON.stringify(rs))
                self.$router.replace({ path: self.$route.fullPath, query: { data: ec} }).catch(()=>{});
            }
        }else{
            data.name = self.$route.name
            rs.items = [data]
            var fgh = btoa(JSON.stringify(rs))
            rs.items[rs.items.length - 1].data = fgh
            fgh = btoa(JSON.stringify(rs))
            self.$router.replace({ path: self.$route.fullPath, query: { data: fgh} }).catch(()=>{});
        }
    }else{
        data.name = self.$route.name
        var encodedString = btoa(JSON.stringify({items:[data]}));
        data.data = encodedString
        encodedString = btoa(JSON.stringify({items:[data]}));
        self.$router.replace({path: self.$route.fullPath, query: { data: encodedString} }).catch(()=>{});
    }
    return BredcrumpManagerDecode(self)
}
export function BredcrumpManagerDecode(self) {
    if (self.$route.query.data){
        var rs = JSON.parse(atob(self.$route.query.data))
        if (rs.items){
            for (let i = 0; i < rs.items.length; i++) {
                if (rs.items[i].path === self.$route.path){
                    return rs.items[i]
                }
            }
            return null
        }else{
            return null
        }
    }else{
        return null
    }
}