<script>
export default {
  data() {
    return {
      breadcrumbs: [],
    };
  },
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    }
  },
  watch: {
    '$route'() {
      this.updateBreadcrumbs();
    },
  },
  created() {
    this.updateBreadcrumbs();
  },
  methods: {
    updateBreadcrumbs() {
      if (this.$route.query.data) {
        const self = this;
        var rs = JSON.parse(atob(self.$route.query.data))
        if (rs.items) {
          this.breadcrumbs = rs.items.map(function (item) {
            if (item.path == self.$route.path) {
              return { text: self.$t(item.title), active: false, to: { name: item.name, query: { data: item.data } } }
            } else {
              return { text: self.$t(item.title), active: false, to: { name: item.name, query: { data: item.data } } }
            }
          })
        }
      }
    },
  },
}
</script>

<template>
  <!-- start page title -->
  <div class="container-fluid">
    <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">{{ title }}</h4>

        <div class="page-title-right">
          <b-breadcrumb :items="breadcrumbs" class="m-0"></b-breadcrumb>
        </div>
      </div>
    </div>
  </div>
    </div>


  <!-- end page title -->
</template>
